<template>
  <div class="row">
    <div class="col-lg-12">
      <b-button @click="creat" class="btn btn-success">{{ $t("common.new") }}</b-button>
    </div>
    <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
      <table class="table table-bordered">
        <thead class="thead-light">
        <tr>
          <th class="text-left" rowspan="2">
            Окуу китептеринин аттары
            <br>
            Наименование учебников
          </th>
          <th class="text-left" colspan="3">
            на первую половину
          </th>
          <th class="text-left" colspan="3">
            на вторую половину
          </th>
          <th class="text-left" rowspan="2"></th>
        </tr>
        <tr>
          <th class="text-left">
            Мектептин мектепкана-сынан алын-ган окуу китебинин саны
            <br>
            Кол-во учеб. получ. в школьной библиотеке
          </th>
          <th class="text-left">
            Ата-энелердин эсебинен ж.б. жол менен алынган окуу китебинин саны
            <br>
            Кол-во учебни-ков приобретен-ных родителями и др
          </th>
          <th class="text-left">
            Китем менен камсыз болушу %
            <br>
            Обеспеченность в %
          </th>
          <th class="text-left">
            Мектептин мектепкана-сынан алын-ган окуу китебинин саны
            <br>
            Кол-во учеб. получ. в школьной библиотеке
          </th>
          <th class="text-left">
            Ата-энелердин эсебинен ж.б. жол менен алынган окуу китебинин саны
            <br>
            Кол-во учебни-ков приобретен-ных родителями и др
          </th>
          <th class="text-left">
            Китем менен камсыз болушу %
            <br>
            Обеспеченность в %
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>{{ item.name }}</td>
          <td>{{ item.firstHalfSuppliedBySchool }}</td>
          <td>{{ item.firstHalfSuppliedByParents }}</td>
          <td>{{ item.firstHalfSupplyRate }}</td>
          <td>{{ item.secondHalfSuppliedBySchool }}</td>
          <td>{{ item.secondHalfSuppliedByParents }}</td>
          <td>{{ item.secondHalfSupplyRate }}</td>
          <td>
            <b-button
                type="submit"
                @click="edit(item)"
                variant="primary"
            >
              {{ $t("common.edit") }}
            </b-button>
            <b-button
                type="submit"
                @click="remove(item)"
                variant="danger"
            >
              {{ $t("common.delete") }}
            </b-button>
          </td>
        </tr>
        </tbody>
      </table>
      <div>
        <b-modal id="informationAboutBooks-modal" ref="informationAboutBooks-modal">
          <template v-slot:modal-title>
            {{ $t("common.new") }}
          </template>
          <b-form>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutBooks.list.name") }}
              </template>
              <b-form-input
                  v-model="form.name"
                  :state="modelState.name"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.name }}</div>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutBooks.list.firstHalfSuppliedBySchool") }}
              </template>
              <b-form-input
                  v-model="form.firstHalfSuppliedBySchool"
                  :state="modelState.firstHalfSuppliedBySchool"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.firstHalfSuppliedBySchool }}</div>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutBooks.list.firstHalfSuppliedByParents") }}
              </template>
              <b-form-input
                  v-model="form.firstHalfSuppliedByParents"
                  :state="modelState.firstHalfSuppliedByParents"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.firstHalfSuppliedByParents }}</div>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutBooks.list.firstHalfSupplyRate") }}
              </template>
              <b-form-input
                  v-model="form.firstHalfSupplyRate"
                  :state="modelState.firstHalfSupplyRate"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.firstHalfSupplyRate }}</div>
            </b-form-group>   
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutBooks.list.secondHalfSuppliedBySchool") }}
              </template>
              <b-form-input
                  v-model="form.secondHalfSuppliedBySchool"
                  :state="modelState.secondHalfSuppliedBySchool"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.secondHalfSuppliedBySchool }}</div>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutBooks.list.secondHalfSuppliedByParents") }}
              </template>
              <b-form-input
                  v-model="form.secondHalfSuppliedByParents"
                  :state="modelState.secondHalfSuppliedByParents"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.secondHalfSuppliedByParents }}</div>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutBooks.list.secondHalfSupplyRate") }}
              </template>
              <b-form-input
                  v-model="form.secondHalfSupplyRate"
                  :state="modelState.secondHalfSupplyRate"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.secondHalfSupplyRate }}</div>
            </b-form-group>
          </b-form>
          

          <template v-slot:modal-footer>
            <b-button type="submit" @click="onSubmit" variant="primary" id="save-subject-data-button">
              {{ $t("common.save") }}
            </b-button>
            <b-button type="reset" @click="onReset" variant="danger">
              {{ $t("common.cancel") }}
            </b-button>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";

export default {
  name: "InformationAboutBooks",
  components: {
  },
  props:{
    groupId:String
  },
  data() {
    return {
      modalName: "informationAboutBooks-modal",
      items: [],
      form: {
        name: "",
        firstHalfSuppliedBySchool: "",
        firstHalfSuppliedByParents: "",
        firstHalfSupplyRate: "",
        secondHalfSuppliedBySchool: "",
        secondHalfSuppliedByParents: "",
        secondHalfSupplyRate: "",
      },
      modelState: {},
      modelSaveErrors: {}
    };
  },
  mounted() {    
    this.reloadData();
  },
  methods: {
    onSubmit(evt) {
      let submitButton = document.getElementById("save-subject-data-button");
      submitButton.classList.add("kt-spinner", "kt-spinner--light", "disabled");
      
      evt.preventDefault();
      let $this = this;
      this.form.groupId = this.groupId;

      ApiService.postSecured("journal/additional/saveInformationAboutBooks", this.form)
          .then(function () {
            $this.$refs[$this.modalName].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.modelSaveErrors = response.data.errors;

              for (let i in response.data.errors) {

                $this.modelState[i] = false;
              }
            }else{
              alert("Ошибка, перезайдите или обратитесь к администратору");
            }
          }).finally(function () {
            submitButton.classList.remove("kt-spinner", "kt-spinner--light", "disabled");
          });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.cleanModel();

      this.$refs[this.modalName].hide();
    },
    reloadData() {
      ApiService.querySecured("journal/additional/informationAboutBooks", {
        params: {groupId: this.groupId}
      }).then(({data}) => {
        this.items = data;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    cleanModel() {
      this.form = {};
      this.modelState = {};
      this.modelSaveErrors = {};
    },
    edit(item) {
      this.form = item;
      this.$refs[this.modalName].show();
    },
    remove(item) {
      let $this = this;
      if(confirm("Удалить?")){
        ApiService.postSecured("journal/additional/deleteInformationAboutBooks", {id:item.id})
            .then(function () {

              $this.reloadData();
            })
            .catch(() => {

            }).finally(function () {

        });
      }
    },
    creat(){
      this.form = {};
      this.$refs[this.modalName].show();
    }
  }
};
</script>
