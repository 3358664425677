<template>
  <div>
    <StudentAbsenceNumberOfDaysTable ref="groupStudentAbsenceNumberOfDaysTable"></StudentAbsenceNumberOfDaysTable>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import StudentAbsenceNumberOfDaysTable from "@/views/pages/edu/schools/reports/partials/StudentAbsenceNumberOfDaysTable.vue";
export default {
  name: "GroupStudentAbsenceNumberOfDaysReport",
  components: {
    StudentAbsenceNumberOfDaysTable
  },
  props:{
    groupId:String
  },
  data() {
    return {

    };
  },
  mounted() {
  },
  methods: {    
    reloadData(){
      this.$refs['groupStudentAbsenceNumberOfDaysTable'].redrawTable(parseInt(this.groupId));
    }
  }
};
</script>
