<template>
  <div>
    <GroupFinalMarksCountReportTable ref="groupFinalMarksCountReportTable"></GroupFinalMarksCountReportTable>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import GroupFinalMarksCountReportTable from "@/views/pages/edu/schools/reports/partials/GroupFinalMarksCountReportTable.vue";
export default {
  name: "GroupFinalMarksCountReport",
  components: {
    GroupFinalMarksCountReportTable
  },
  props:{
    groupId:String
  },
  data() {
    return {

    };
  },
  mounted() {
  },
  methods: {    
    reloadData(){
      this.$refs['groupFinalMarksCountReportTable'].redrawTable(parseInt(this.groupId));
    }
  }
};
</script>
