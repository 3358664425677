<template>
  <div>
    <InformationAboutStudentMovementTable ref="groupInformationAboutStudentMovementTable"></InformationAboutStudentMovementTable>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import InformationAboutStudentMovementTable from "@/views/pages/edu/schools/reports/partials/InformationAboutStudentMovementTable.vue";
export default {
  name: "GroupInformationAboutStudentMovementReport",
  components: {
    InformationAboutStudentMovementTable
  },
  props:{
    groupId:String
  },
  data() {
    return {

    };
  },
  mounted() {
  },
  methods: {    
    reloadData(){
      this.$refs['groupInformationAboutStudentMovementTable'].redrawTable(parseInt(this.groupId));
    }
  }
};
</script>
