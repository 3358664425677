<template>
  <div class="row">
    <div class="col-lg-12">
<!--      <b-button @click="creat" class="btn btn-success">{{ $t("common.new") }}</b-button>-->
    </div>
    <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
      <table class="table table-bordered">
        <thead class="thead-light">
        <tr>
          <th class="text-left" rowspan="3">
            {{ $t("informationAboutClassesInClub.list.name") }}
          </th>
          <th class="text-left" colspan="4">
            {{ $t("informationAboutClassesInClub.list.clubs") }}
          </th>
          <th class="text-left" colspan="2">
            {{ $t("informationAboutClassesInClub.list.elective") }}
          </th>          
          <th class="text-left" rowspan="3">
            
          </th>
        </tr>
        <tr>
          <th class="text-left">
            I
          </th>
          <th class="text-left">
            II
          </th>
          <th class="text-left">
            I
          </th>
          <th class="text-left">
            II
          </th>
          <th class="text-left">
            I
          </th>
          <th class="text-left">
            II
          </th>
        </tr>
        <tr>
          <th class="text-left">
            {{ $t("informationAboutClassesInClub.list.clubNameFirstHalf") }}
          </th>
          <th class="text-left">
            {{ $t("informationAboutClassesInClub.list.clubOrgNameFirstHalf") }}
          </th>
          <th class="text-left">
            {{ $t("informationAboutClassesInClub.list.clubNameSecondHalf") }}
          </th>
          <th class="text-left">
            {{ $t("informationAboutClassesInClub.list.clubOrgNameSecondHalf") }}
          </th>
          <th class="text-left">
            {{ $t("informationAboutClassesInClub.list.electiveNameFirstHalf") }}
          </th>
          <th class="text-left">
            {{ $t("informationAboutClassesInClub.list.electiveNameSecondHalf") }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>{{ item.studentName }}</td>
          <td>{{ item.clubNameFirstHalf }}</td>
          <td>{{ item.clubOrgNameFirstHalf }}</td>
          <td>{{ item.clubNameSecondHalf }}</td>
          <td>{{ item.clubOrgNameSecondHalf }}</td>
          <td>{{ item.electiveNameFirstHalf }}</td>
          <td>{{ item.electiveNameSecondHalf }}</td>
          <td>
            <b-button
                type="submit"
                @click="edit(item)"
                variant="primary"
            >
              {{ $t("common.edit") }}
            </b-button>
          </td>
        </tr>
        </tbody>
      </table>
      <div>
        <b-modal id="informationAboutClassesInClub-modal" ref="informationAboutClassesInClub-modal">
          <template v-slot:modal-title>
            {{ $t("common.new") }}
          </template>
          <b-form>
            <div>
              {{ $t("informationAboutClassesInClub.list.name") }} - {{form.studentName}}
            </div>
            <h6>{{ $t("informationAboutClassesInClub.list.clubs") }}</h6>
            <h6>I</h6>
            <div class="separator separator-dashed my-10"></div>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutClassesInClub.list.clubNameFirstHalf") }}
              </template>
              <b-form-input
                  v-model="form.clubNameFirstHalf"
                  :state="modelState.clubNameFirstHalf"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.clubNameFirstHalf }}</div>
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutClassesInClub.list.clubOrgNameFirstHalf") }}
              </template>
              <b-form-input
                  v-model="form.clubOrgNameFirstHalf"
                  :state="modelState.clubOrgNameFirstHalf"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.clubOrgNameFirstHalf }}</div>
            </b-form-group>
            
            <h6>II</h6>
            <div class="separator separator-dashed my-10"></div>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutClassesInClub.list.clubNameSecondHalf") }}
              </template>
              <b-form-input
                  v-model="form.clubNameSecondHalf"
                  :state="modelState.clubNameSecondHalf"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.clubNameSecondHalf }}</div>
            </b-form-group>            
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutClassesInClub.list.clubOrgNameSecondHalf") }}
              </template>
              <b-form-input
                  v-model="form.clubOrgNameSecondHalf"
                  :state="modelState.clubOrgNameSecondHalf"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.clubOrgNameSecondHalf }}</div>
            </b-form-group>

            <h6>{{ $t("informationAboutClassesInClub.list.elective") }}</h6>
            <h6>I</h6>
            <div class="separator separator-dashed my-10"></div>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutClassesInClub.list.electiveNameFirstHalf") }}
              </template>
              <b-form-input
                  v-model="form.electiveNameFirstHalf"
                  :state="modelState.electiveNameFirstHalf"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.electiveNameFirstHalf }}</div>
            </b-form-group>
            <h6>II</h6>
            <div class="separator separator-dashed my-10"></div>
            <b-form-group>
              <template v-slot:label>
                {{ $t("informationAboutClassesInClub.list.electiveNameSecondHalf") }}
              </template>
              <b-form-input
                  v-model="form.electiveNameSecondHalf"
                  :state="modelState.electiveNameSecondHalf"
              ></b-form-input>
              <div class="invalid-feedback">{{ modelSaveErrors.electiveNameSecondHalf }}</div>
            </b-form-group>
          </b-form>          

          <template v-slot:modal-footer>
            <b-button type="submit" @click="onSubmit" variant="primary" id="save-subject-data-button">
              {{ $t("common.save") }}
            </b-button>
            <b-button type="reset" @click="onReset" variant="danger">
              {{ $t("common.cancel") }}
            </b-button>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<style>
.separator.separator-dashed {
  border-bottom: 1px dashed #000000;
}
.separator {
  height: 0;
}
</style>

<script>
import ApiService from "@/common/api.service";

export default {
  name: "InformationAboutClassesInClub",
  components: {
  },
  props:{
    groupId:String
  },
  data() {
    return {
      modalName: "informationAboutClassesInClub-modal",
      items: [],
      form: {
        studentId: 0,
        studentName: "",
        socUsefulWorkFirstHalf: "",
        socUsefulWorkSecondHalf: "",
        socUsefulWorkContent: "",
      },
      modelState: {},
      modelSaveErrors: {}
    };
  },
  mounted() {    
    this.reloadData();
  },
  methods: {
    onSubmit(evt) {
      let submitButton = document.getElementById("save-subject-data-button");
      submitButton.classList.add("kt-spinner", "kt-spinner--light", "disabled");
      
      evt.preventDefault();
      let $this = this;
      this.form.groupId = this.groupId;

      ApiService.postSecured("journal/additional/saveInformationAboutClassesInClubForGroup", this.form)
          .then(function () {
            $this.$refs[$this.modalName].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.modelSaveErrors = response.data.errors;

              for (let i in response.data.errors) {

                $this.modelState[i] = false;
              }
            }else{
              alert("Ошибка, перезайдите или обратитесь к администратору");
            }
          }).finally(function () {
            submitButton.classList.remove("kt-spinner", "kt-spinner--light", "disabled");
          });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs[this.modalName].hide();
    },
    reloadData() {
      ApiService.querySecured("journal/additional/informationAboutClassesInClub", {
        params: {groupId: this.groupId}
      }).then(({data}) => {
        this.items = data;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    cleanModel() {
      this.form = {};
    },
    edit(item) {
      this.form = item;
      this.$refs[this.modalName].show();
    },
    creat(){
      this.form = {};
      this.$refs[this.modalName].show();
    }
  }
};
</script>
