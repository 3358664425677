<template>
  <div>
    <SchoolSocialPassportReportTable ref="groupSocialPassportReportTable"></SchoolSocialPassportReportTable>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import ApiService from "@/common/api.service";
import SchoolSocialPassportReportTable from "@/views/pages/edu/schools/reports/partials/SchoolSocialPassportReportTable.vue";

export default {
  name: "GroupSocialPassport",
  components: {
    SchoolSocialPassportReportTable
  },
  props:{
    groupId:String
  },
  data() {
    return {

    };
  },
  mounted() {
  },
  methods: {    
    reloadData(){
      let $this = this;
      let filter = {
        studentGroupId: parseInt(this.groupId)
      };
      
      ApiService.postSecured("schoolReports/socialPassport", filter).then(({data}) => {        
        let typeConfig = {};
        
        for (let i in data.reportItems){
          let group = data.reportItems[i];
          typeConfig[i] = JSON.parse(group.config);
        }

        $this.$refs['groupSocialPassportReportTable'].redrawTable(typeConfig, data.reportItems);
      }).catch(({response}) => {
        console.log(response);
      });
    }
  }
};
</script>
