<template>
  <div>
    <div class="pb-4 pull-right">
      <b-button @click="createNote" class="btn btn-success" v-if="showButtons()">{{ $t("common.new") }}</b-button>
    </div>
    <table class="table table-bordered">
      <thead class="thead-light">
      <tr>
        <th class="text-left">
          Дата
        </th>
        <th class="text-left">
          Замечание и предложения
        </th>
        <th class="text-left">
          Отметка о выполнении
        </th>
        <th>          
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in notes" :key="item.id">
        <td>
          {{item.dateString}}
        </td>
        <td>
          {{item.note}}
        </td>
        <td>
          {{item.result}}
        </td>
        <td>
          <a class="btn btn-icon btn-success" v-if="showButtons()"> 
            <i class="fa fa-pen" @click="editNote(item)"></i>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
    <b-modal id="journal-notes-modal" ref="journal-notes-modal">
      <template v-slot:modal-title>
        {{ $t("common.new") }}
      </template>
      <b-form>
        <b-form-group>
          <template v-slot:label>
            Дата
          </template>
          <b-form-datepicker
              v-model="form.date"
              :state="formStates.dropDate"
              locale="ru"
          ></b-form-datepicker>
          <div class="invalid-feedback">{{ formSaveError.dropDate }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Замечания или предложения
          </template>
          <b-form-input
              v-model="form.note"
              :state="formStates.note"
          ></b-form-input>
          <div class="invalid-feedback">{{ formSaveError.note }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Отметка о выполнении
          </template>
          <b-form-input
              v-model="form.result"
              :state="formStates.result"
          ></b-form-input>
          <div class="invalid-feedback">{{ formSaveError.result }}</div>
        </b-form-group>
      </b-form>

      <template v-slot:modal-footer>
        <b-button type="submit" @click="saveNote" variant="primary">{{
            $t("common.save")
          }}
        </b-button>
        <b-button type="reset" @click="closeNoteForm" variant="danger">{{
            $t("common.cancel")
          }}
        </b-button>
      </template>
    </b-modal>
    
  </div>
</template>
<style>
</style>
<script>

import ApiService from "@/common/api.service";
import {mapGetters} from "vuex";
export default {
  name: "JournalNotes",
  components: {},
  props: {
    groupId: String,
  },
  data() {
    return {
      notes:[],
      form:{
        date: new Date(),
        note:'',
        result:''
      },
      formStates:{},
      formSaveError:{},      
    };
  },
  mounted() {
    //this.reloadData();
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser"
    ])
  },
  methods: {
    reloadData(){
      ApiService.querySecured("journalNote/list", {
        params: {groupId: this.groupId}
      }).then(({data}) => {
        this.notes = data;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    createNote(){
      this.formStates = {};
      this.formSaveError = {};

      this.form = {
        groupId: this.groupId
      };
      this.$refs["journal-notes-modal"].show();
    },

    saveNote(evt) {
      evt.preventDefault();
      let $this = this;

      this.formStates = {};
      this.formSaveError = {};

      ApiService.postSecured("journalNote/save", this.form)
          .then(function () {
            $this.$refs["journal-notes-modal"].hide();
          
            $this.form = {};
            $this.reloadData();
          })
          .catch(({response}) => {
            
            if (response.data != null && response.data.errors != null) {
              $this.formSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.formStates[i] = false;
              }
            }
          });
    },
    editNote(subject) {
      this.formStates = {};
      this.formSaveError = {};

      this.form = subject;
      this.form['groupId'] = this.groupId;
      
      this.$refs["journal-notes-modal"].show();
    },
    closeNoteForm(evt) {
      evt.preventDefault();
      this.form = {};
      
      this.$refs["journal-notes-modal"].hide();
    },
    
    showButtons(){
      return this.currentUser.role == 'ROLE_SCHOOL_ADMIN';
    }
  }
};
</script>
