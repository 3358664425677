<template>
  <div>
    {{group.groupName}}
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>

export default {
  name: "GroupName",
  components: {
   
  },
  props:{
    group:{
      type: Object,
      default: (()=>{})
    }
  },
  data() {
    return {
      
    };
  },
  mounted() {
   
  },
  methods: {
    
  }
};
</script>
